import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import React from 'react';
import { helpCenterUrl } from '../../constants';

const HelpCenterLink = () => (
  <Button
    id="help-center"
    disableTouchRipple
    sx={{
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }}
  >
    <Link
      href={helpCenterUrl}
      target="_blank"
      color="text.main"
      underline="none"
      fontWeight="light"
      sx={{
        '&:hover': {
          color: 'primary.main',
        },
      }}
    >
      Centro de ayuda
    </Link>
  </Button>
);

export default HelpCenterLink;
